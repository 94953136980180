<template>
  <div class="container global-list-main-container">
    <div class="general-balance-all-graph-container">
      <div
        class="global-lists-filters-container special-margin-top"
        id="filter-container"
      >
        <b-field
          label="Fecha de inicio"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.startDate }"
          :message="{
            'La fecha de inicio no es valida': errors.startDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeDateFilterValue()"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de inicio"
            icon="calendar-today"
            v-model="startDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
        <b-field
          label="Fecha de fin"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.endDate }"
          :message="{
            'La fecha de fin no es valida': errors.endDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeDateFilterValue()"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de fin"
            icon="calendar-today"
            v-model="endDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
      </div>
      <div class="global-lists-filters-container" id="filter-container">
        <b-field label="Filtrar por cliente" label-position="inside">
          <b-select
            placeholder="Filtrar por clientes"
            icon="account-multiple"
            v-model="filterClient"
            @input="(value) => changeDateFilterValue()"
            class="global-lists-filters-control"
          >
            <option value="">Todos los clientes</option>
            <option
              v-for="client in clients"
              :value="client._id"
              :key="client.NOMBRE_EMPRESA"
            >
              {{ client.NOMBRE_EMPRESA }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Filtrar por almacen" label-position="inside">
          <b-select
            placeholder="Filtrar por almacen"
            icon="warehouse"
            v-model="filterWarehouse"
            @input="(value) => changeDateFilterValue()"
          >
            <option value="">Todos los almacenes</option>
            <option
              v-for="warehouse in warehouses"
              :value="warehouse._id"
              :key="warehouse.NOMBRE_ALM"
            >
              {{ warehouse.NOMBRE_ALM }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="global-lists-filters-container" id="filter-container">
        <b-field
          label="Filtrar por categoría o sub categoría"
          label-position="inside"
        >
          <b-select
            placeholder="Filtrar por categoría o sub categoría"
            icon="shape"
            v-model="filterCategory"
            @input="(value) => changeDateFilterValue()"
          >
            <option value="">Todas las categorías y sub categorías</option>
            <option
              v-for="category in allCategories"
              :value="category._id"
              :key="category._id"
            >
              {{ category.CLAVE_FAM }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="information-cards-toogle">
        <b-field>
          <b-switch v-model="isBruteSwitch"> Cantidades Brutas </b-switch>
        </b-field>
      </div>
      <div class="text-center">
        Filtros disponibles para esta sección Fecha de inicio, Fecha de fin,
        Almacen, cliente y categoría o sub categoría
      </div>
      <hr class="analytics-divider" />
      <div class="information-cards-container">
        <InformationCard
          :name="
            !isBruteSwitch
              ? 'Ventas menos cancelaciones, notas de crédito y clarificaciones (Netas)'
              : 'Ventas menos cancelaciones, notas de crédito y clarificaciones (Brutas)'
          "
          :value="
            !isBruteSwitch
              ? salesAnalyticInformation.total -
                salesAnalyticInformation.totalCancellations -
                salesAnalyticInformation.totalClarification -
                salesAnalyticInformation.totalCreditNotes
              : salesAnalyticInformation.totalNoTaxes -
                salesAnalyticInformation.totalCancellationsNoTaxes -
                salesAnalyticInformation.totalClarificationNoTaxes -
                salesAnalyticInformation.totalCreditNotesNoTaxes
          "
          :max="
            !isBruteSwitch
              ? salesAnalyticInformation.total -
                salesAnalyticInformation.totalCancellations -
                salesAnalyticInformation.totalClarification -
                salesAnalyticInformation.totalCreditNotes
              : salesAnalyticInformation.totalNoTaxes -
                salesAnalyticInformation.totalCancellationsNoTaxes -
                salesAnalyticInformation.totalClarificationNoTaxes -
                salesAnalyticInformation.totalCreditNotesNoTaxes
          "
          format="money"
          width="full"
          :moreDetails="true"
          class="special-margin"
        />

        <InformationCard
          :name="!isBruteSwitch ? 'Ventas (Netas)' : 'Ventas (Brutas)'"
          :value="
            !isBruteSwitch
              ? salesAnalyticInformation.total
              : salesAnalyticInformation.totalNoTaxes
          "
          :max="
            !isBruteSwitch
              ? salesAnalyticInformation.total
              : salesAnalyticInformation.totalNoTaxes
          "
          format="money"
          width="half"
          :moreDetails="true"
          firstDetailMessage="Número de ventas"
          :firstDetailValue="salesAnalyticInformation.quantity"
          :secondDetailMessage="
            !isBruteSwitch
              ? 'Promedio de total en ventas (Netas)'
              : 'Promedio de total en ventas (Brutas)'
          "
          :secondDetailValue="
            !isBruteSwitch
              ? salesAnalyticInformation.average
              : salesAnalyticInformation.averageNoTaxes
          "
          class="special-margin"
        />
        <InformationCard
          :name="
            !isBruteSwitch ? 'Cancelaciones (Netas)' : 'Cancelaciones (Brutas)'
          "
          :value="
            !isBruteSwitch
              ? salesAnalyticInformation.totalCancellations
              : salesAnalyticInformation.totalCancellationsNoTaxes
          "
          :max="
            !isBruteSwitch
              ? salesAnalyticInformation.totalCancellations
              : salesAnalyticInformation.totalCancellationsNoTaxes
          "
          format="money"
          width="half"
          :moreDetails="true"
          firstDetailMessage="Número de cancelaciones"
          :firstDetailValue="salesAnalyticInformation.quantityCancellations"
          :secondDetailMessage="
            !isBruteSwitch
              ? 'Promedio de cancelaciones (Netas)'
              : 'Promedio de cancelaciones (Brutas)'
          "
          :secondDetailValue="
            !isBruteSwitch
              ? salesAnalyticInformation.averageCancellations
              : salesAnalyticInformation.averageCancellationsNoTaxes
          "
          class="special-margin"
        />

        <InformationCard
          :name="
            !isBruteSwitch
              ? 'Clarificaciones (Netas)'
              : 'Clarificaciones (Brutas)'
          "
          :value="
            !isBruteSwitch
              ? salesAnalyticInformation.totalClarification
              : salesAnalyticInformation.totalClarificationNoTaxes
          "
          :max="
            !isBruteSwitch
              ? salesAnalyticInformation.totalClarification
              : salesAnalyticInformation.totalClarificationNoTaxes
          "
          format="money"
          width="half"
          :moreDetails="true"
          firstDetailMessage="Número de clarificaciones"
          :firstDetailValue="salesAnalyticInformation.quantityClarification"
          :secondDetailMessage="
            !isBruteSwitch
              ? 'Promedio de clarificaciones (Netas)'
              : 'Promedio de clarificaciones (Brutas)'
          "
          :secondDetailValue="
            !isBruteSwitch
              ? salesAnalyticInformation.averageClarification
              : salesAnalyticInformation.averageClarificationNoTaxes
          "
          class="special-margin"
        />
        <InformationCard
          :name="
            !isBruteSwitch
              ? 'Notas de crédito (Netas)'
              : 'Notas de crédito (Brutas)'
          "
          :value="
            !isBruteSwitch
              ? salesAnalyticInformation.totalCreditNotes
              : salesAnalyticInformation.totalCreditNotesNoTaxes
          "
          :max="
            !isBruteSwitch
              ? salesAnalyticInformation.totalCreditNotes
              : salesAnalyticInformation.totalCreditNotesNoTaxes
          "
          format="money"
          width="half"
          :moreDetails="true"
          firstDetailMessage="Número de notas de crédito"
          :firstDetailValue="salesAnalyticInformation.quantityCreditNotes"
          :secondDetailMessage="
            !isBruteSwitch
              ? 'Promedio de notas de crédito (Netas)'
              : 'Promedio de notas de crédito (Brutas)'
          "
          :secondDetailValue="
            !isBruteSwitch
              ? salesAnalyticInformation.averageCreditNotes
              : salesAnalyticInformation.averageCreditNotesNoTaxes
          "
          class="special-margin"
        />
      </div>

      <div class="text-center">
        Filtros disponibles para esta sección Fecha de inicio, Fecha de fin y
        Almacen
      </div>
      <hr class="analytics-divider" />
      <div class="initial-lists-container">
        <div class="initial-single-list-full">
          <p class="initial-table-name">Total de ventas por almacen</p>
          <b-table
            :data="salesAnalyticInformationPerWarehouse"
            pagination-position="both"
            :striped="true"
            :bordered="true"
            :hoverable="true"
            paginated
            :current-page="currentPageSalesWarehouses"
            :per-page="perPageSalesWarehouses"
            default-sort="name"
            default-sort-direction="desc"
            scrollable
          >
            <template #empty>
              <div class="has-text-centered">No hay almacenes</div>
            </template>

            <template slot-scope="props">
              <b-table-column
                v-for="column in warehousesSalesColumns"
                v-bind="column"
                :key="column.field"
                :label="column.label"
                :sortable="column.sortable"
                :searchable="column.searchable"
                :custom-sort="column.customSort"
              >
                <span v-if="!checkIfValid(props.row[column.field])">{{
                  "Sin información"
                }}</span>
                <span
                  v-if="column.money && checkIfValid(props.row[column.field])"
                  >{{ props.row[column.field] | money("MXN", 2) }}</span
                >
                <span
                  v-if="!column.money && checkIfValid(props.row[column.field])"
                  >{{ props.row[column.field] }}</span
                >
              </b-table-column>
            </template>
          </b-table>
        </div>
      </div>

      <div class="initial-lists-container">
        <div class="initial-single-list">
          <div class="text-center">
            Filtros disponibles para esta sección Fecha de inicio, Fecha de fin
            y Almacen
          </div>
          <hr class="analytics-divider" />
          <p class="initial-table-name">Total de ventas por cliente</p>
          <div class="initial-single-list-filter"></div>
          <b-table
            :data="clientSales"
            pagination-position="both"
            :striped="true"
            :bordered="true"
            :hoverable="true"
            paginated
            :current-page="currentPageClientSales"
            :per-page="perPageClientSales"
            default-sort="name"
            default-sort-direction="desc"
            scrollable
          >
            <template #empty>
              <div class="has-text-centered">No hay ventas</div>
            </template>

            <template slot-scope="props">
              <b-table-column
                v-for="column in clientsSalesColumns"
                v-bind="column"
                :key="column.field"
                :label="column.label"
                :sortable="column.sortable"
                :searchable="column.searchable"
              >
                <p v-if="column.money">
                  {{ props.row[column.field] | money("MXN", 2) }}
                </p>
                <p v-if="!column.money">
                  {{ props.row[column.field] }}
                </p>
              </b-table-column>
            </template>
          </b-table>
        </div>
        <div class="initial-single-list">
          <div class="text-center">
            Filtros disponibles para esta sección Fecha de inicio, Fecha de fin,
            Almacen y Cantidad
          </div>
          <hr class="analytics-divider" />
          <p class="initial-table-name">
            Top {{ filterMostSold }} de artículos más vendidos
          </p>
          <div class="initial-single-list-filter">
            <b-field
              label="Cantidad de artículos a filtrar"
              label-position="inside"
            >
              <b-input
                placeholder="Ejemplo: 50"
                expanded
                icon="numeric"
                :value="filterMostSold"
                @change.native="filterMostSold = $event.target.value"
                type="number"
                min="0"
                step="1"
              ></b-input>
            </b-field>
          </div>
          <b-table
            :data="articlesSales"
            pagination-position="both"
            :striped="true"
            :bordered="true"
            :hoverable="true"
            paginated
            :current-page="currentPageArticlesSales"
            :per-page="perPageArticlesSales"
            default-sort="name"
            default-sort-direction="desc"
            scrollable
          >
            <template #empty>
              <div class="has-text-centered">No hay artículos</div>
            </template>

            <template slot-scope="props">
              <b-table-column
                v-for="column in articlesSalesColumns"
                v-bind="column"
                :key="column.field"
                :label="column.label"
                :sortable="column.sortable"
                :searchable="column.searchable"
              >
                <p v-if="column.money">
                  {{ props.row[column.field] | money("MXN", 2) }}
                </p>
                <p v-if="!column.money">
                  {{ props.row[column.field] }}
                </p>
              </b-table-column>
            </template>
          </b-table>
        </div>
      </div>

      <div class="text-center">
        Filtros disponibles para esta sección Fecha de inicio, Fecha de fin,
        Almacen y Cantidad
      </div>
      <hr class="analytics-divider" />
      <div class="initial-lists-container">
        <div class="initial-single-list-full">
          <p class="initial-table-name">
            Top {{ filterMostSoldMoney }} de artículos más vendidos por volumen
            de venta
          </p>
          <div class="initial-single-list-filter">
            <b-field
              label="Cantidad de artículos a filtrar"
              label-position="inside"
            >
              <b-input
                placeholder="Ejemplo: 50"
                expanded
                icon="numeric"
                :value="filterMostSoldMoney"
                @change.native="filterMostSoldMoney = $event.target.value"
                type="number"
                min="0"
                step="1"
              ></b-input>
            </b-field>
          </div>
          <b-table
            :data="articlesMoneySale"
            pagination-position="both"
            :striped="true"
            :bordered="true"
            :hoverable="true"
            paginated
            :current-page="currentPageArticlesMoneySale"
            :per-page="perPageArticlesMoneySale"
            default-sort="name"
            default-sort-direction="desc"
            scrollable
          >
            <template #empty>
              <div class="has-text-centered">No hay ventas</div>
            </template>

            <template slot-scope="props">
              <b-table-column
                v-for="column in articlesSalesMoneyColumns"
                v-bind="column"
                :key="column.field"
                :label="column.label"
                :sortable="column.sortable"
                :searchable="column.searchable"
                :custom-sort="column.customSort"
              >
                <span v-if="!checkIfValid(props.row[column.field])">{{
                  "Sin información"
                }}</span>
                <span
                  v-if="column.money && checkIfValid(props.row[column.field])"
                  >{{ props.row[column.field] | money("MXN", 2) }}</span
                >
                <span
                  v-if="!column.money && checkIfValid(props.row[column.field])"
                  >{{ props.row[column.field] }}</span
                >
              </b-table-column>
            </template>
          </b-table>
        </div>
      </div>

      <div class="text-center">
        Filtros disponibles para esta sección Fecha de inicio, Fecha de fin,
        Almacen
      </div>
      <hr class="analytics-divider" />
      <div class="initial-lists-container">
        <div class="initial-single-list-full">
          <p class="initial-table-name">Cancelaciones de ventas</p>
          <b-table
            :data="cancellationsArray.cancellations"
            pagination-position="both"
            :striped="true"
            :bordered="true"
            :hoverable="true"
            paginated
            :current-page="currentPageCancellations"
            :per-page="perPageCancellations"
            default-sort="name"
            default-sort-direction="desc"
            scrollable
          >
            <template #empty>
              <div class="has-text-centered">
                No hay cancelaciones de ventas
              </div>
            </template>

            <template slot-scope="props">
              <b-table-column
                v-for="column in cancellationsColumns"
                v-bind="column"
                :key="column.field"
                :label="column.label"
                :sortable="column.sortable"
                :searchable="column.searchable"
                :custom-sort="column.customSort"
              >
                <span v-if="!checkIfValid(props.row[column.field])">{{
                  "Sin información"
                }}</span>
                <span
                  v-if="column.money && checkIfValid(props.row[column.field])"
                  >{{ props.row[column.field] | money("MXN", 2) }}</span
                >
                <span
                  v-if="!column.money && checkIfValid(props.row[column.field])"
                  >{{ props.row[column.field] }}</span
                >
              </b-table-column>
            </template>

            <template #footer>
              <div class="has-text-right">
                Total en todas las cancelaciones:
                {{ cancellationsArray.cancellationsTotal | money("MXN", 2) }}
              </div>
            </template>
          </b-table>
        </div>
      </div>

      <div class="text-center">
        Filtros disponibles para esta sección Fecha de inicio, Fecha de fin,
        Almacen
      </div>
      <hr class="analytics-divider" />
      <div class="initial-lists-container">
        <div class="initial-single-list-full">
          <p class="initial-table-name">Movimientos manuales</p>
          <b-table
            :data="manualMovementsArray.movements"
            pagination-position="both"
            :striped="true"
            :bordered="true"
            :hoverable="true"
            paginated
            :current-page="currentPageMovements"
            :per-page="perPageMovements"
            default-sort="name"
            default-sort-direction="desc"
            scrollable
          >
            <template #empty>
              <div class="has-text-centered">No hay movimientos manuales</div>
            </template>

            <template slot-scope="props">
              <b-table-column
                v-for="column in manualMovementsColumns"
                v-bind="column"
                :key="column.field"
                :label="column.label"
                :sortable="column.sortable"
                :searchable="column.searchable"
                :custom-sort="column.customSort"
              >
                <span v-if="!checkIfValid(props.row[column.field])">{{
                  "Sin información"
                }}</span>
                <span
                  v-if="column.money && checkIfValid(props.row[column.field])"
                  >{{ props.row[column.field] | money("MXN", 2) }}</span
                >
                <span
                  v-if="!column.money && checkIfValid(props.row[column.field])"
                  >{{ props.row[column.field] }}</span
                >
              </b-table-column>
            </template>

            <template #footer>
              <div class="has-text-right">
                Total en todos los movimientos:
                {{
                  manualMovementsArray.manualMovementsTotal | money("MXN", 2)
                }}
              </div>
            </template>
          </b-table>
        </div>
      </div>

      <div class="text-center">
        Filtros disponibles para esta sección Fecha de inicio, Fecha de fin,
        Almacen
      </div>
      <hr class="analytics-divider" />
      <div class="initial-lists-container">
        <div class="initial-single-list-full">
          <p class="initial-table-name">Artículos en ventas</p>
          <b-table
            :data="salesArticlesArray"
            pagination-position="both"
            :striped="true"
            :bordered="true"
            :hoverable="true"
            paginated
            :current-page="currentPageSalesArticles"
            :per-page="perPageSalesArticles"
            default-sort="name"
            default-sort-direction="desc"
            scrollable
          >
            <template #empty>
              <div class="has-text-centered">
                No hay cancelaciones de ventas
              </div>
            </template>

            <template slot-scope="props">
              <b-table-column
                v-for="column in salesArticlesColumns"
                v-bind="column"
                :key="column.field"
                :label="column.label"
                :sortable="column.sortable"
                :searchable="column.searchable"
                :custom-sort="column.customSort"
              >
                <span v-if="!checkIfValid(props.row[column.field])">{{
                  "Sin información"
                }}</span>
                <span
                  v-if="column.money && checkIfValid(props.row[column.field])"
                  >{{ props.row[column.field] | money("MXN", 2) }}</span
                >
                <span
                  v-if="
                    column.percentage && checkIfValid(props.row[column.field])
                  "
                  >{{ props.row[column.field] }}%</span
                >
                <span
                  v-if="
                    !column.money &&
                    !column.percentage &&
                    checkIfValid(props.row[column.field])
                  "
                  >{{ props.row[column.field] }}</span
                >
              </b-table-column>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import InformationCard from "../../Global/components/InformationCard";

// @ is an alias to /src
export default {
  name: "AnalyticsSales",
  components: {
    InformationCard,
  },
  data() {
    return {
      moment: moment,
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 15
      ),
      endDate: new Date(),
      errors: {
        startDate: false,
        endDate: false,
      },
      isBruteSwitch: false,
      filterClient: "",
      filterWarehouse: "",
      filterCategory: "",
      filterSeller: "",
      salesAnalyticInformation: {
        total: 0,
        totalNoTaxes: 0,
        quantity: 0,
        average: 0,
        averageNoTaxes: 0,
        totalCancellations: 0,
        totalCancellationsNoTaxes: 0,
        quantityCancellations: 0,
        averageCancellations: 0,
        averageCancellationsNoTaxes: 0,
        totalCreditNotes: 0,
        totalCreditNotesNoTaxes: 0,
        quantityCreditNotes: 0,
        averageCreditNotes: 0,
        averageCreditNotesNoTaxes: 0,
        totalClarification: 0,
        totalClarificationNoTaxes: 0,
        averageClarification: 0,
        averageClarificationNoTaxes: 0,
        quantityClarification: 0,
      },
      salesAnalyticInformationPerWarehouse: [],
      clientSales: [],
      articlesSales: [],
      salesArticlesArray: [],
      articlesMoneySale: [],
      manualMovementsArray: {
        movements: [],
        manualMovementsTotal: 0,
      },
      cancellationsArray: {
        cancellations: [],
        cancellationsTotal: 0,
      },
      warehousesSalesColumns: [
        {
          field: "CLAVE_ALMACEN",
          label: "Clave",
          sortable: true,
          searchable: true,
        },
        {
          field: "NOMBRE_ALM",
          label: "Nombre",
          sortable: true,
          searchable: true,
        },
        {
          field: "totalNoTaxes",
          label: "Ventas bruto",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
        },
        {
          field: "total",
          label: "Ventas neto",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
        },
        {
          field: "totalCancellationsNoTaxes",
          label: "Cancelaciones bruto",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
        },
        {
          field: "totalCancellations",
          label: "Cancelaciones neto",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
        },
        {
          field: "totalClarificationNoTaxes",
          label: "Clarificaciones bruto",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
        },
        {
          field: "totalClarification",
          label: "Clarificaciones neto",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
        },
        {
          field: "totalCreditNotesNoTaxes",
          label: "Notas de crédito bruto",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
        },
        {
          field: "totalCreditNotes",
          label: "Notas de crédito neto",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
        },
        {
          field: "brute",
          label: "Total final bruto",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
        },
        {
          field: "net",
          label: "Total final neto",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
        },
      ],
      clientsSalesColumns: [
        {
          field: "name",
          label: "Clave",
          sortable: true,
          searchable: true,
        },
        {
          field: "quantity",
          label: "Cantidad",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
        },
        {
          field: "totalSales",
          label: "Número de ventas",
          numeric: true,
          money: false,
          sortable: true,
          searchable: false,
        },
      ],
      articlesSalesColumns: [
        {
          field: "name",
          label: "Clave",
          sortable: true,
          searchable: true,
        },
        {
          field: "description",
          label: "Descripción",
          sortable: true,
          searchable: true,
        },
        {
          field: "quantity",
          label: "Cantidad",
          numeric: true,
          money: false,
          sortable: true,
          searchable: false,
        },
        {
          field: "salesQuantity",
          label: "Ventas",
          numeric: true,
          money: false,
          sortable: true,
          searchable: false,
        },
      ],
      articlesSalesMoneyColumns: [
        {
          field: "name",
          label: "Clave",
          sortable: true,
          searchable: true,
        },
        {
          field: "description",
          label: "Descripción",
          sortable: true,
          searchable: true,
        },
        {
          field: "quantity",
          label: "Cantidad",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
        },
      ],
      manualMovementsColumns: [
        {
          field: "DESCRIPCION",
          label: "Descripción",
          sortable: true,
          searchable: true,
        },
        {
          field: "CANTIDAD",
          label: "Cantidad",
          numeric: true,
          money: false,
          sortable: true,
          searchable: false,
        },
        {
          field: "creationDateFormatted",
          label: "Fecha",
          numeric: true,
          money: false,
          sortable: true,
          searchable: false,
          customSort: this.sortByDate,
        },
      ],
      cancellationsColumns: [
        {
          field: "clientName",
          label: "Cliente",
          sortable: true,
          searchable: true,
        },
        {
          field: "CLAVE_VENTA",
          label: "Folio",
          sortable: true,
          searchable: true,
        },
        {
          field: "FACTURA_FOR",
          label: "Facturas",
          sortable: true,
          searchable: true,
        },
        {
          field: "NOMBRE_VENDEDOR",
          label: "Vendedor",
          sortable: true,
          searchable: true,
        },
        {
          field: "creationDateFormatted",
          label: "Fecha",
          sortable: true,
          searchable: true,
        },
        {
          field: "cancellationDateFormatted",
          label: "Fecha de cancelación",
          sortable: true,
          searchable: true,
        },
        {
          field: "USUARIO_CAN",
          label: "Usuario cancelación",
          sortable: true,
          searchable: true,
        },
        {
          field: "SUBTOTAL",
          label: "Sub total",
          sortable: true,
          searchable: true,
          money: true,
        },
        {
          field: "TOTAL",
          label: "Total",
          sortable: true,
          searchable: true,
          money: true,
        },
      ],
      salesArticlesColumns: [
        {
          field: "CLAVE DEL ARTICULO",
          label: "Clave",
          sortable: true,
          searchable: true,
        },
        {
          field: "DESCRIPCIÓN DEL ARTICULO",
          label: "Descripción",
          sortable: true,
          searchable: true,
        },
        {
          field: "CLIENTE",
          label: "Cliente",
          sortable: true,
          searchable: true,
        },
        {
          field: "VENTA",
          label: "Venta",
          sortable: true,
          searchable: true,
        },
        {
          field: "FECHA_FOR",
          label: "Fecha",
          sortable: true,
          searchable: true,
          customSort: this.sortByDateArticlesSales,
        },
        {
          field: "CANTIDAD",
          label: "Cantidad",
          sortable: true,
          searchable: true,
        },
        {
          field: "COSTO",
          label: "Costo",
          sortable: true,
          searchable: true,
          money: true,
        },
        {
          field: "DESCUENTO",
          label: "Descuentos",
          sortable: true,
          searchable: true,
          money: true,
        },
        {
          field: "INDIRECTOS",
          label: "Indirectos",
          sortable: true,
          searchable: true,
          money: true,
        },
        {
          field: "PRECIO DE VENTA",
          label: "Precio de venta",
          sortable: true,
          searchable: true,
          money: true,
        },
        {
          field: "UTILIDAD",
          label: "Utilidad",
          sortable: true,
          searchable: true,
          money: true,
        },
        {
          field: "PORCENTAGE DE UTILIDAD",
          label: "Porcentaje de Utilidad",
          sortable: true,
          searchable: true,
          percentage: true,
        },
        {
          field: "UTILIDAD TOTAL",
          label: "Utilidad total",
          sortable: true,
          searchable: true,
          percentage: false,
          money: true,
        },
      ],
      perPage: 50,
      perPageSalesWarehouses: 50,
      perPageClientSales: 50,
      perPageArticlesSales: 50,
      perPageArticlesMoneySale: 50,
      perPageSalesArticles: 50,
      perPageCancellations: 50,
      perPageMovements: 50,
      currentPage: 1,
      currentPageSalesWarehouses: 1,
      currentPageClientSales: 1,
      currentPageArticlesSales: 1,
      currentPageArticlesMoneySale: 1,
      currentPageSalesArticles: 1,
      currentPageCancellations: 1,
      currentPageMovements: 1,
      filterMostSold: 50,
      filterMostSoldMoney: 50,
    };
  },
  methods: {
    async getAllSalesInformation() {
      try {
        let response = await this.$store.dispatch("GETCOMPANYSALESDATE", {
          startDate: this.startDate,
          endDate: new Date(
            new Date(this.endDate).getFullYear(),
            new Date(this.endDate).getMonth(),
            new Date(this.endDate).getDate() + 1
          ),
          warehouse: this.filterWarehouse,
          client: this.filterClient,
          category: this.filterCategory,
          seller: this.filterSeller,
        });

        if (response) {
          this.salesAnalyticInformation = response;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getAllSalesInformationPerWarehouse() {
      try {
        if (this.warehouses.length > 0) {
          this.salesAnalyticInformationPerWarehouse = [];
          this.$store.commit("SET_LOADING", true);

          for (const singleWarehouse of this.warehouses) {
            let response = await this.$store.dispatch("GETCOMPANYSALESDATE", {
              startDate: this.startDate,
              endDate: new Date(
                new Date(this.endDate).getFullYear(),
                new Date(this.endDate).getMonth(),
                new Date(this.endDate).getDate() + 1
              ),
              warehouse: singleWarehouse._id,
              client: this.filterClient,
              category: this.filterCategory,
              seller: this.filterSeller,
            });

            if (response) {
              const netWarehousesSale =
                response.total -
                response.totalCancellations -
                response.totalClarification -
                response.totalCreditNotes;

              const bruteWarehouseSale =
                response.totalNoTaxes -
                response.totalCancellationsNoTaxes -
                response.totalClarificationNoTaxes -
                response.totalCreditNotesNoTaxes;

              this.salesAnalyticInformationPerWarehouse.push({
                CLAVE_ALMACEN: singleWarehouse.CLAVE_ALMACEN,
                NOMBRE_ALM: singleWarehouse.NOMBRE_ALM,
                net: netWarehousesSale,
                brute: bruteWarehouseSale,
                ...response,
              });
            }
          }

          this.$store.commit("SET_LOADING", false);
        }
      } catch (error) {
        console.log(error);
        this.$store.commit("SET_LOADING", false);
      }
    },
    async getAllClientsInformation() {
      try {
        let response = await this.$store.dispatch("GETCOMPANYCLIENTSSLIST", {
          startDate: this.startDate,
          endDate: new Date(
            new Date(this.endDate).getFullYear(),
            new Date(this.endDate).getMonth(),
            new Date(this.endDate).getDate() + 1
          ),
          warehouse: this.filterWarehouse,
        });

        if (response && response.clientsNumberSalesArray) {
          this.clientSales = response.clientsNumberSalesArray;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getAllSalesArticlesInformation() {
      try {
        let response = await this.$store.dispatch(
          "GETCOMPANYARTICLESLISTDATE",
          {
            startDate: this.startDate,
            endDate: new Date(
              new Date(this.endDate).getFullYear(),
              new Date(this.endDate).getMonth(),
              new Date(this.endDate).getDate() + 1
            ),
            warehouse: this.filterWarehouse,
            quantity: this.filterMostSold,
          }
        );

        if (response && response.mostSold) {
          this.articlesSales = response.mostSold;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getAllSalesArticlesMoneyInformation() {
      try {
        let response = await this.$store.dispatch(
          "GETCOMPANYARTICLESMONEYLISTDATE",
          {
            startDate: this.startDate,
            endDate: new Date(
              new Date(this.endDate).getFullYear(),
              new Date(this.endDate).getMonth(),
              new Date(this.endDate).getDate() + 1
            ),
            warehouse: this.filterWarehouse,
            quantity: this.filterMostSoldMoney,
          }
        );

        if (response && response.mostSold) {
          this.articlesMoneySale = response.mostSold;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getAllManualMovementsInformation() {
      try {
        let response = await this.$store.dispatch("GETMANUALMOVEMENTSDATE", {
          startDate: this.startDate,
          endDate: new Date(
            new Date(this.endDate).getFullYear(),
            new Date(this.endDate).getMonth(),
            new Date(this.endDate).getDate() + 1
          ),
        });

        if (response && response.movements) {
          this.manualMovementsArray = response;

          this.manualMovementsArray.movements =
            this.manualMovementsArray.movements.map((singleMovement) => {
              const movementsWithProps = { ...singleMovement };
              movementsWithProps.creationDateFormatted = moment(
                singleMovement.FECHA
              ).format("DD-MM-YYYY HH:mm");
              return movementsWithProps;
            });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getAllCancellationsInformation() {
      try {
        let response = await this.$store.dispatch("GETCANCELLATIONSDATE", {
          startDate: this.startDate,
          endDate: new Date(
            new Date(this.endDate).getFullYear(),
            new Date(this.endDate).getMonth(),
            new Date(this.endDate).getDate() + 1
          ),
          warehouse: this.filterWarehouse,
          client: this.filterClient,
        });

        if (response && response.cancellations) {
          this.cancellationsArray = response;

          this.cancellationsArray.cancellations =
            this.cancellationsArray.cancellations.map((singleSale) => {
              const saleWithProps = { ...singleSale };

              saleWithProps.creationDateFormatted = moment(
                singleSale.FECHA
              ).format("DD-MM-YYYY HH:mm");
              saleWithProps.cancellationDateFormatted = moment(
                singleSale.FECHA_CAN
              ).format("DD-MM-YYYY HH:mm");
              saleWithProps.clientName =
                singleSale.CLAVE_CLIENTE.NOMBRE_EMPRESA;
              saleWithProps.FACTURA_FOR = singleSale.FACTURAS.map(
                (singleInvoice) => {
                  return singleInvoice.CLAVE_FACTURA;
                }
              ).toString();
              saleWithProps.SUBTOTAL = String(singleSale.SUBTOTAL);
              saleWithProps.TOTAL = String(singleSale.TOTAL);
              return saleWithProps;
            });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getAllCompanySalesArticles() {
      try {
        let response = await this.$store.dispatch(
          "GETCOMPANYSALESARTICLESDATE",
          {
            startDate: this.startDate,
            endDate: new Date(
              new Date(this.endDate).getFullYear(),
              new Date(this.endDate).getMonth(),
              new Date(this.endDate).getDate() + 1
            ),
            warehouse: this.filterWarehouse,
            client: this.filterClient,
          }
        );

        if (response && response.salesArticles) {
          this.salesArticlesArray = response.salesArticles;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async changeDateFilterValue() {
      if (moment(new Date(this.startDate)).isAfter(new Date(this.endDate))) {
        this.errors.starDate = true;
      } else {
        this.errors.starDate = false;
      }

      if (moment(new Date(this.endDate)).isBefore(new Date(this.starDate))) {
        this.errors.endDate = true;
      } else {
        this.errors.endDate = false;
      }

      if (!this.errors.startDate && !this.errors.endDate) {
        const promiseArray = [];

        promiseArray.push(this.getAllSalesInformation());
        promiseArray.push(this.getAllSalesInformationPerWarehouse());
        promiseArray.push(this.getAllManualMovementsInformation());
        promiseArray.push(this.getAllCancellationsInformation());
        promiseArray.push(this.getAllCompanySalesArticles());
        promiseArray.push(this.getAllClientsInformation());
        promiseArray.push(this.getAllSalesArticlesInformation());
        promiseArray.push(this.getAllSalesArticlesMoneyInformation());

        await Promise.all(promiseArray);
      }
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA).getTime() || -Infinity) -
          (new Date(a.FECHA).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA).getTime() || -Infinity) -
          (new Date(b.FECHA).getTime() || -Infinity)
        );
      }
    },
    sortByDateArticlesSales(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA).getTime() || -Infinity) -
          (new Date(a.FECHA).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA).getTime() || -Infinity) -
          (new Date(b.FECHA).getTime() || -Infinity)
        );
      }
    },
  },
  async created() {
    moment.locale("es");

    let allPromises = [];

    allPromises.push(this.$store.dispatch("GETCATEGORIES"));
    allPromises.push(this.$store.dispatch("GETALLSCATEGORIES"));

    await Promise.all(allPromises);
  },
  mounted() {
    this.getAllSalesInformation();
    this.getAllSalesInformationPerWarehouse();
    this.getAllCancellationsInformation();
    this.getAllCompanySalesArticles();
    this.getAllManualMovementsInformation();
    this.getAllClientsInformation();
    this.getAllSalesArticlesInformation();
    this.getAllSalesArticlesMoneyInformation();
  },
  computed: {
    clients() {
      return this.$store.getters.CLIENTS.map((singleClient) => {
        const clientWithProps = { ...singleClient };
        return clientWithProps;
      }).sort((a, b) => {
        return a.NOMBRE_EMPRESA > b.NOMBRE_EMPRESA ? 1 : -1;
      });
    },
    warehouses() {
      return this.$store.getters.WAREHOUSES.map((singleWarehouse) => {
        let singleWarehouseWithProps = { ...singleWarehouse };
        return singleWarehouseWithProps;
      }).filter((singleWarehouse) => {
        return singleWarehouse.CLAVE_ALMACEN !== "Transferencias";
      });
    },
    sales() {
      return this.$store.getters.SALES.map((singleSale) => {
        const saleWithProps = { ...singleSale };

        saleWithProps.TOTAL = String(singleSale.TOTAL);

        saleWithProps.posFormatted = singleSale.POS === "S" ? "Sí" : "No";

        if (singleSale.CLAVE_ALMACEN) {
          saleWithProps.NOMBRE_ALM = singleSale.CLAVE_ALMACEN.NOMBRE_ALM;
        }

        if (singleSale.CLAVE_CLIENTE) {
          saleWithProps.NOMBRE_CLIENTE =
            singleSale.CLAVE_CLIENTE.NOMBRE_EMPRESA;
        }

        saleWithProps.formatedCreationDate = moment(singleSale.FECHA).format(
          "DD-MM-YYYY HH:mm"
        );

        saleWithProps.advancedPaymentFormatted = "";
        if (saleWithProps.ANTICIPO_INFO) {
          saleWithProps.advancedPaymentFormatted =
            saleWithProps.ANTICIPO_INFO.NUM_FOLIO;
        }

        saleWithProps.advancedPaymentCreditNote = "No";
        if (saleWithProps.FACTURAS) {
          for (const singleInvoice of saleWithProps.FACTURAS) {
            for (const singleCreditNote of singleInvoice.NOTAC) {
              if (singleCreditNote.TIPO === "A") {
                saleWithProps.advancedPaymentCreditNote = "Si";
              }
            }
          }
        }

        saleWithProps.TIENE_OC_FOR = "No";
        if (saleWithProps.ORDENES_COMPRA.length > 0) {
          saleWithProps.TIENE_OC_FOR = "Si";
        }

        saleWithProps.FACTURA_FOR = singleSale.FACTURAS.map((singleInvoice) => {
          return singleInvoice.CLAVE_FACTURA;
        }).toString();

        if (saleWithProps.STATUS === "AC") {
          saleWithProps.statusFormatted = "Activa";
        } else if (saleWithProps.STATUS === "CA") {
          saleWithProps.statusFormatted = "Cancelada";
          saleWithProps.RESTA = 0;
        } else if (saleWithProps.STATUS === "AN") {
          saleWithProps.statusFormatted = "Activa Nota Crédito";
        }

        if (!saleWithProps.STATUS_FAC || saleWithProps.STATUS_FAC === "SF") {
          saleWithProps.statusFacFormatted = "Sin timbrar";
        } else if (saleWithProps.STATUS_FAC === "TI") {
          saleWithProps.statusFacFormatted = "Timbrada";
        } else if (saleWithProps.STATUS_FAC === "CA") {
          saleWithProps.statusFacFormatted = "Cancelada";
        } else if (saleWithProps.STATUS_FAC === "FD") {
          saleWithProps.statusFacFormatted = "En factura global";
        }

        if (!saleWithProps.STATUS_ENV || saleWithProps.STATUS_ENV === "P") {
          saleWithProps.statusDeliveryFormatted = "Pendiente";
        } else if (saleWithProps.STATUS_ENV === "I") {
          saleWithProps.statusDeliveryFormatted = "Incompleto";
        } else if (saleWithProps.STATUS_ENV === "C") {
          saleWithProps.statusDeliveryFormatted = "Completo";
        }

        saleWithProps.RESTA_FOR = 0;
        for (const singleIncome of saleWithProps.CUENTAS_COB) {
          saleWithProps.RESTA_FOR += singleIncome.RESTA;
        }
        saleWithProps.RESTA_FOR = String(saleWithProps.RESTA_FOR);

        saleWithProps.CUENTA_STATUS_FOR = "Pendiente de pagar";
        for (const singleIncome of saleWithProps.CUENTAS_COB) {
          if (singleIncome.STATUS === "AC")
            saleWithProps.CUENTA_STATUS_FOR = "Pendiente de pagar";
          if (singleIncome.STATUS === "PE")
            saleWithProps.CUENTA_STATUS_FOR = "Pendiente de pagar";
          if (singleIncome.STATUS === "PA")
            saleWithProps.CUENTA_STATUS_FOR = "Totalmente pagada";
          if (singleIncome.STATUS === "CA") {
            saleWithProps.CUENTA_STATUS_FOR = "Cancelada";
            saleWithProps.RESTA_FOR = 0;
          }
          if (singleIncome.STATUS === "VE") {
            saleWithProps.CUENTA_STATUS_FOR = "Vencida";
          }
        }

        return saleWithProps;
      });
    },
    categories() {
      return this.$store.getters.CATEGORIES.map((singleCategory) => {
        let singleCategoryWithProp = { ...singleCategory };
        return singleCategoryWithProp;
      });
    },
    sCategories() {
      return this.$store.getters.SUBCATEGORIES.map((singleCategory) => {
        let singleCategoryWithProp = { ...singleCategory };

        singleCategoryWithProp.CLAVE_FAM = singleCategory.CLAVE_SFAM;

        return singleCategoryWithProp;
      });
    },
    sSCategories() {
      return this.$store.getters.SUBSUBCATEGORIES.map((singleCategory) => {
        let singleCategoryWithProp = { ...singleCategory };

        singleCategoryWithProp.CLAVE_FAM = singleCategory.CLAVE_SSFAM;

        return singleCategoryWithProp;
      });
    },
    sSSCategories() {
      return this.$store.getters.SUBSUBSUBCATEGORIES.map((singleCategory) => {
        let singleCategoryWithProp = { ...singleCategory };

        singleCategoryWithProp.CLAVE_FAM = singleCategory.CLAVE_SSSFAM;

        return singleCategoryWithProp;
      });
    },
    allCategories() {
      return [
        ...this.categories,
        ...this.sCategories,
        ...this.sSCategories,
        ...this.sSSCategories,
      ];
    },
  },
  watch: {
    filterMostSold() {
      this.getAllSalesArticlesInformation();
    },
    filterMostSoldMoney() {
      this.getAllSalesArticlesMoneyInformation();
    },
    warehouses() {
      this.getAllSalesInformationPerWarehouse();
    },
  },
};
</script>

<style scoped>
@import "../../Bank/style/GeneralResumeeBank.css";
@import "../../Global/style/Global.css";
</style>
