<template>
  <div class="container global-list-main-container">
    <Navbar :showSearch="false"></Navbar>
    <div class="absolut-width global-lists-second-section-container">
      <div class="global-list-main-container">
        <div class="upper-global-title">
          <span class=""> Analisis y reportes </span>
        </div>
        <div class="upper-global-hint">
          <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
          <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
        </div>
        <b-tabs type="is-boxed" v-if="appLoaded">
          <b-tab-item label="Compras" icon="account-group-outline">
            <AnalyticsPurchases />
          </b-tab-item>
          <b-tab-item label="Ventas" icon="point-of-sale">
            <AnalyticsSales />
          </b-tab-item>
          <b-tab-item label="Cuentas por pagar" icon="currency-usd-off">
            <AnalyticsExpenses />
          </b-tab-item>
          <b-tab-item label="Cuentas por cobrar" icon="currency-usd">
            <AnalyticsIncomes />
          </b-tab-item>
          <b-tab-item label="Transferencias" icon="file-swap-outline">
            <AnalyticsTransfers />
          </b-tab-item>
          <b-tab-item label="Personal" icon="clipboard-arrow-down-outline">
            <AnalyticsPersonal />
          </b-tab-item>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Navbar";
import AnalyticsPurchases from "../components/AnalyticsPurchases";
import AnalyticsSales from "../components/AnalyticsSales";
import AnalyticsIncomes from "../components/AnalyticsIncomes";
import AnalyticsExpenses from "../components/AnalyticsExpenses";
import AnalyticsPersonal from "../components/AnalyticsPersonal";
import AnalyticsTransfers from "../components/AnalyticsTransfers";

// @ is an alias to /src
export default {
  name: "AnalyticsLists",
  components: {
    Navbar,
    AnalyticsPurchases,
    AnalyticsSales,
    AnalyticsIncomes,
    AnalyticsExpenses,
    AnalyticsPersonal,
    AnalyticsTransfers,
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("CHANGEACTIVE", "analytics");
  },
  methods: {},
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    appLoaded() {
      return this.$store.getters.LOADED;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
